import React from 'react';

const NotFoundPage = () => {
  return (
    <div>
      <div>
        404 Page Not Found
      </div>
    </div>
  );
};

export default NotFoundPage;
